<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import type { DropdownAlign } from '~/types/ui';

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndSmaller = breakpoints.smallerOrEqual('md');

const isOpen = defineModel<boolean>();

const props = withDefaults(
  defineProps<{
    align?: DropdownAlign;
    width?: string;
    offset?: number;
    type?: number;
    contentStyle?: string | object[] | object | Array<string>;
    mode?: 'click' | 'hover';
    showInMobile?: boolean;
    disabled?: boolean;
  }>(),
  {
    align: 'auto',
    width: '200px',
    offset: 8,
    type: 1,
    contentStyle: '',
    mode: 'click',
    showInMobile: true,
    withSearch: false,
    disabled: false,
  }
);
</script>

<template>
  <UPopover
    v-model:open="isOpen"
    :mode="props.mode"
    :popper="{
      placement: props.align,
      offsetDistance: props.offset,
    }"
    class="w-full"
    :disabled="disabled"
    :ui="{
      base: `overflow-hidden flex-col justify-start items-start gap-1 inline-flex ${props.type === 1 ? 'p-3' : ''}`,
      background: 'bg-ht-primary-bg-fill dark:bg-ht-primary-bg-fill',
      rounded: 'rounded-xl',
      ring: 'ring-ht-border-2 dark:ring-ht-border-2',
      width: `w-[${props.width}]`,
      shadow:
        'drop-shadow-hub-dropdown-light dark:drop-shadow-hub-dropdown-dark',
    }"
  >
    <slot />

    <template #panel="slotProps">
      <div
        v-if="!mdAndSmaller && props.type === 1"
        :class="[props.contentStyle, `w-[${props.width}]`]"
      >
        <slot name="content" v-bind="slotProps" />
      </div>

      <div
        v-else-if="!mdAndSmaller && props.type === 2"
        :class="props.contentStyle"
      >
        <slot name="content" v-bind="slotProps" />
      </div>
    </template>
  </UPopover>
</template>
